import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import LinkCard from "../../components/LinkCard"

const IndexPage = () => {
    return (
        <Layout pageTitle="Members">
            <Row>
                <Col>
                    <Link to="/">Back</Link>
                </Col>
            </Row>
            <Row className="mt-5 justify-content-center">
                <LinkCard to="/members/my-success-story" title="My Success Story" bg="pink" />
                <LinkCard to="/members/my-boardroom" title="My Boardroom" bg="pink" />
                <LinkCard to="/members/badges" title="Member Badges" />
                <LinkCard to="/members/standard-graphics" title="Standard Graphics" />
                <LinkCard to="/members/templates" title="Templates" />
                <LinkCard to="/members/press-releases" title="Press Releases"  />
                



            </Row>
        </Layout>
    )
}

export default IndexPage
